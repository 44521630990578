
import Vue from 'vue';
import { gql } from 'graphql-tag';
import UserSelector from './UserSelector.vue';
import ChatAvatar from './ChatAvatar.vue';
import { throttle } from 'lodash';
import { AES as cryptoAES, enc as cryptoEnc } from 'crypto-js';
import { Autolinker } from 'autolinker';
import RoomNameEditor from './RoomNameEditor.vue';

import {
  User,
  ChatMessage,
  ChatMessageCreateInput,
  ChatRoom,
  ChatRoomOnUser,
} from './type';
import { DocumentNode } from 'graphql';
import { mapActions } from 'vuex';

interface SubscribeResponse {
  unsubscribe?: () => unknown;
}

export default Vue.extend({
  name: 'Chat',

  components: { UserSelector, ChatAvatar, RoomNameEditor },

  props: { roomId: { type: String, required: true } },

  data: () => ({
    isReady: false,
    messages: [] as ChatMessage[],
    text: '',
    skeltonLoading: true,
    subLoding: false,
    room: ({
      ChatRoomOnUser: ([] as unknown) as ChatRoomOnUser,
    } as unknown) as ChatRoom,
    selected: [] as User[],
    addDialog: false,
    // subしているもの
    currentSubs: {} as Record<string, SubscribeResponse[]>,
    // メッセージ読み込み関数
    loadMessageFunc: (() => {
      /**/
    }) as Function,
    // ルーム読み込み関数
    loadRoomFunc: (() => {
      /**/
    }) as Function,
    // 自動スクロール対象
    isAutoScroll: true,
    // ルーム名の編集モーダル
    roomNameModal: false,
  }),

  computed: {
    // このルームにいるユーザのId
    roomUserIds() {
      const idSet = new Set([
        ...(this.room.ChatRoomOnUser?.map((u) => u.User.Id) || []),
      ]);
      return [...idSet];
    },
    messageIds(): string[] {
      return this.messages.map((m) => m.Id);
    },
  },

  watch: {
    async roomId(to, from) {
      if (to !== from) {
        this.skeltonLoading = true;
        this.messages = [];
        await this.loadRoom();
        await this.loadMessages({ forceScroll: true });
      }
    },
  },

  async mounted() {
    this.isReady = true;
    // メッセージ読み込み関数
    this.loadMessageFunc = throttle(this.loadMessages, 1.5 * 1000, {});
    // ルーム読み込み関数
    this.loadRoomFunc = throttle(this.loadRoom, 15 * 1000, {});

    setTimeout(async () => {
      if (this.isReady) {
        this.skeltonLoading = true;
        await this.loadMessageFunc({ forceScroll: true });
        await this.subscribe();
        this.skeltonLoading = false;
      }
    }, 2 * 1000);
  },

  async beforeDestroy() {
    this.isReady = false;
    // subを解除
    for (const key of Object.keys(this.currentSubs)) {
      await this.unSubscribe(key);
    }
  },

  methods: {
    /**
     * 末尾に移動
     */
    scrollBottom(force?: boolean) {
      if (this.isAutoScroll || force) {
        const chatWindow = this.$refs.chatWindow as Element;
        this.$nextTick(() => (chatWindow.scrollTop = chatWindow.scrollHeight));
      }
    },
    /**
     * スクロールのハンドラ
     */
    handleScrollInChat({ target }: { target: HTMLElement }) {
      const { scrollTop, scrollHeight, clientHeight } = target;
      if (clientHeight > scrollHeight) {
        // スクロールはないので自動をtrueにしておく
        this.isAutoScroll = true;
        return;
      }
      if (scrollHeight - scrollTop - clientHeight < 100) {
        // 下までスクロールしているので自動スクロール対象
        this.isAutoScroll = true;
        return;
      } else {
        // 上にさかのぼっているので対象外
        this.isAutoScroll = false;
        return;
      }
    },
    /**
     * ルームにユーザを追加する
     */
    async onAddClick() {
      try {
        if (this.selected.length > 0) {
          await this.$store.dispatch('loading/register', this.addUserToRoom());
        }
        this.selected = [];
        this.addDialog = false;
      } catch (error) {
        console.error(error);
        this.openErrorSnackBar({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          message: `エラーが発生しました。 ${(error as any).message}`,
        });
      }
    },
    /**
     * メンバーの追加
     */
    async addUserToRoom() {
      try {
        await Promise.all(
          this.selected.map(async (user) => {
            await this.$apollo.mutate({
              mutation: /* GraphQL */ gql`
                mutation updateChatRoomUser($data: ChatRoomOnUserCreateInput!) {
                  createChatRoomOnUser(data: $data) {
                    Id
                    chatRoomId
                    userId
                  }
                }
              `,
              variables: {
                data: {
                  ChatRoom: {
                    connect: {
                      Id: this.room?.Id,
                    },
                  },
                  User: {
                    connect: {
                      Id: user.Id,
                    },
                  },
                },
              },
            });
          }),
        );
      } catch (error) {
        console.error(error);
        this.openErrorSnackBar({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          message: `エラーが発生しました。 ${(error as any).message}`,
        });
      }
    },
    /**
     * ルーム情報の取得
     */
    async loadRoom() {
      if (!this.roomId) return;
      try {
        // ChatRoomの取得
        this.room =
          (
            await this.$apollo.query({
              query: /* GraphQL */ gql`
                query getChatRoomQuery($Id: String) {
                  getChatRoom(where: { Id: $Id }) {
                    Name
                    Id
                    ChatRoomOnUser {
                      User {
                        LastLoginDate
                        Avatar
                        Name
                        Id
                      }
                    }
                  }
                }
              `,
              variables: { Id: this.roomId },
            })
          ).data?.getChatRoom || {};
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * メッセージの取得
     */
    async loadMessages(option?: { forceScroll?: boolean }) {
      // console.log('chatmessage load', new Date().toJSON());
      if (!this.roomId) return;
      try {
        // Roomの情報を適当なタイミングで更新
        await this.loadRoomFunc();
        // Roomのメッセージを作成日でソートして取得
        this.messages = (
          await this.$apollo.query({
            query: /* GraphQL */ gql`
              query getChatMessageQuery(
                $Id: String
                $orderBy: [ChatMessageOrderByInput]
              ) {
                listChatMessages(
                  where: { Room: { some: { Id: { equals: $Id } } } }
                  orderBy: $orderBy
                ) {
                  Id
                  body
                  CreatedDate
                  CreatedBy {
                    Id
                    Name
                    Avatar
                    LastLoginDate
                  }
                  ChatMessageStatus {
                    Id
                    userId
                  }
                }
              }
            `,
            variables: {
              Id: this.roomId,
              orderBy: [{ CreatedDate: 'ASC' }],
            },
          })
        ).data?.listChatMessages;
        this.messages.forEach((msg) => {
          // メッセージが暗号化されているので戻す
          msg.body = this.formatMessage(msg.body);
        });

        // 各メッセージで既読を付けてないものはすべて付ける
        const stilReadMessages = this.messages.filter(
          (msg) =>
            !msg?.ChatMessageStatus?.find(
              (d) => d.userId === this.$store.state.user.user.Id,
            ),
        );
        if (stilReadMessages.length > 0) {
          // 既読状況を更新
          // ユーザを絞ってsubscribeしたいので1件ずつやる
          await Promise.all(
            stilReadMessages.map(async (message) => {
              try {
                // upsertにしてdataに指定するプロパティを減らす
                await this.$apollo.mutate({
                  mutation: /* GraphQL */ gql`
                    mutation upsertChatMessageStatus(
                      $userId: String
                      $chatMessageId: String
                      $dummyId: String
                    ) {
                      upsertChatMessageStatus(
                        data: {
                          User: { connect: { Id: $userId } }
                          ChatMessage: { connect: { Id: $chatMessageId } }
                        }
                        where: { Id: $dummyId }
                      ) {
                        Id
                        userId
                        chatMessageId
                        ChatMessage {
                          userId
                          Room {
                            Id
                            CDS_T_Disaster__c
                          }
                        }
                      }
                    }
                  `,
                  variables: {
                    userId: this.$store.state.user.user.Id,
                    chatMessageId: message?.Id,
                    dummyId: '-1',
                  },
                });
              } catch (error) {
                console.error(error);
              }
            }),
          );
        }
      } catch (error) {
        console.error(error);
      }
      await this.$nextTick();
      // 最新表示
      this.scrollBottom(option?.forceScroll);
      this.skeltonLoading = false;
    },
    /**
     * サブスクライブを行う
     */
    doSubscribe(
      query: DocumentNode,
      variables: unknown,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (value: any) => void,
    ) {
      return this.$apollo
        .subscribe({
          query,
          variables,
        })
        .subscribe({
          next,
          error: console.error,
        });
    },
    /**
     * サブスクライブの解除
     */
    async unSubscribe(key: string) {
      const value = this.currentSubs[key];
      if (value) {
        for (const v of value) {
          try {
            await v?.unsubscribe?.();
          } catch (error) {
            console.warn(error);
          }
        }
      }
      this.currentSubs[key] = [];
    },
    /**
     * メッセージの購読
     */
    async subscribe() {
      await this.subMessage();
      await this.subRoomUser();
      await this.subMessageStatus();
      await this.subUser();
      await this.subRoom();
    },
    // チャットの更新
    async subMessage() {
      const key = 'message_sub';
      // すでに登録済みであればおわり
      if (this.currentSubs[key]?.length > 0) return;
      this.currentSubs[key] = [
        this.doSubscribe(
          /* GraphQL */ gql`
            subscription MessageSubscribe {
              onCreatedChatMessage {
                Room {
                  Id
                }
              }
            }
          `,
          {},
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ data }: { data: { onCreatedChatMessage: any } }) => {
            const res = data.onCreatedChatMessage;
            // メッセージがこのルームのものであれば更新
            if (res.Room.find((r: { Id: string }) => r.Id === this.roomId)) {
              this.loadMessageFunc();
            }
          },
        ),
      ];
    },
    // チャットルームのメンバー更新
    async subRoomUser() {
      const key = 'room_user_sub';
      // すでに登録済みであればおわり
      if (this.currentSubs[key]?.length > 0) return;
      this.currentSubs[key] = [
        this.doSubscribe(
          /* GraphQL */ gql`
            subscription RoomUserSubscribe {
              onCreatedChatRoomOnUser {
                Id
                userId
                chatRoomId
              }
            }
          `,
          {},
          ({
            data: { onCreatedChatRoomOnUser },
          }: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data: { onCreatedChatRoomOnUser: any };
          }) => {
            const { chatRoomId } = onCreatedChatRoomOnUser;
            // 開いているルームのユーザに更新があった時だけ
            if (this.roomId === chatRoomId) {
              this.loadMessageFunc();
            }
          },
        ),
      ];
    },
    // 既読更新
    async subMessageStatus() {
      const key = 'message_status_sub';
      // すでに登録済みであればおわり
      if (this.currentSubs[key]?.length > 0) return;
      // chatRoomId_userIdでフィルタリングできるようになったら実装したい
      this.currentSubs[key] = [
        // 既読更新
        this.doSubscribe(
          /* GraphQL */ gql`
            subscription ChatMessageStatusSubscribe {
              onUpsertedChatMessageStatus {
                Id
                userId
                chatMessageId
              }
            }
          `,
          {},
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ data }: { data: any }) => {
            const res = data.onUpsertedChatMessageStatus;
            // 表示中のメッセージの更新のみ受け取り
            if (this.messageIds.includes(res.chatMessageId)) {
              this.loadMessageFunc();
            }
          },
        ),
      ];
    },
    // ユーザ情報変更時のsub
    async subUser() {
      const key = 'user_sub';
      await this.unSubscribe(key);
      // ユーザを絞って登録
      this.currentSubs[key] = this.roomUserIds.map((userId) => {
        const sub = this.doSubscribe(
          /* GraphQL */ gql`
            subscription UserSubscribe($userId: String) {
              onUpdatedUser(Id: $userId) {
                Id
              }
            }
          `,
          {
            userId,
          },
          () => {
            this.loadMessageFunc();
          },
        );
        return sub;
      });
    },
    // ルーム情報変更時のsub
    async subRoom() {
      const key = 'room_sub';
      // すでに登録済みであればおわり
      if (this.currentSubs[key]?.length > 0) return;
      this.currentSubs[key] = [
        this.doSubscribe(
          /* GraphQL */ gql`
            subscription RoomSubscribe($roomId: String) {
              onUpdatedChatRoom(Id: $roomId) {
                Id
                Name
              }
            }
          `,
          {
            roomId: this.roomId,
          },
          () => {
            this.loadRoom();
          },
        ),
      ];
    },

    /**
     * メッセージの送信
     */
    async submit(event: KeyboardEvent) {
      if (this.text && event.keyCode === 13) {
        const CreatedById = this.$store.state.user.user.Id;
        const saveText = cryptoAES.encrypt(this.text, this.roomId).toString();
        const newMessage: ChatMessageCreateInput = {
          body: saveText,
          CreatedBy: { Id: CreatedById },
        };
        // 送信内容を反映
        this.text = '';
        this.messages.push({
          ...newMessage,
          body: this.decodeMessage(newMessage.body),
        } as ChatMessage);
        this.scrollBottom(true);
        this.subLoding = true;
        try {
          // 送信
          await this.$apollo.mutate({
            mutation: /* GraphQL */ gql`
              mutation CreateChatMessageMutation(
                $data: ChatMessageCreateInput!
              ) {
                createChatMessage(data: $data) {
                  Id
                  body
                  userId
                  CreatedBy {
                    Id
                  }
                  Room {
                    Id
                    Name
                  }
                }
              }
            `,
            variables: {
              data: {
                ...newMessage,
                Room: { connect: { Id: this.roomId } },
                CreatedDate: new Date().toISOString(),
                CreatedBy: { connect: { Id: CreatedById } },
              },
            },
          });
        } catch (error) {
          this.messages.pop();
          console.error(error);
        }
        this.subLoding = false;
      }
    },
    /**
     * 自分のメッセージかどうか判定
     */
    isMyMessage(message: ChatMessage) {
      return message.CreatedBy?.Id === this.$store.state.user.user.Id;
    },
    /**
     * メッセージを表示用に変換する
     */
    formatMessage(msg: string): string {
      // デコード
      const decodedMessage = this.decodeMessage(msg);

      // リンクを付ける
      const linkedMessage = Autolinker.link(decodedMessage, {
        phone: false,
        sanitizeHtml: true,
      });

      return linkedMessage;
    },
    /**
     * メッセージの復元
     */
    decodeMessage(msg: string): string {
      try {
        const decrypted = cryptoAES.decrypt(msg, this.roomId);
        return decrypted.toString(cryptoEnc.Utf8);
      } catch (error) {
        // ignored
      }
      return msg;
    },
    /**
     * 日付と既読
     */
    formatTimeAndStatus(message: ChatMessage): string {
      return [
        // 日付
        this.formatTime(message),
        // 既読
        this.formatStatus(message),
      ]
        .filter((v) => !!v)
        .join(' ');
    },
    // 日付
    formatTime(message: ChatMessage): string {
      if (!message) return '';
      const { CreatedDate: createdDateString } = message;
      try {
        const CreatedDate = new Date((createdDateString as unknown) as string);
        const today = new Date();
        // 今日と同じ日付か
        if (
          CreatedDate?.toISOString().slice(0, 10) ===
          today.toISOString().slice(0, 10)
        ) {
          // 時間だけを返す
          return this.$options.filters?.time(CreatedDate) || '';
        }
        return this.$options.filters?.datetime(CreatedDate) || '';
      } catch (error) {
        return '';
      }
    },
    // 既読
    formatStatus(message: ChatMessage): string {
      // 既読情報が一切なければ空欄にする
      if (
        !message.ChatMessageStatus ||
        !Array.isArray(message.ChatMessageStatus)
      )
        return '';
      // 自分を除いた既読情報
      const statusExceptForMe = message.ChatMessageStatus.filter(
        (c) => c.userId !== this.$store.state.user.user.Id,
      );
      return `既読 ${statusExceptForMe.length}`;
    },

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
});
