
import Vue from 'vue';
import { mapActions } from 'vuex';
import { gql } from 'graphql-tag';

export default Vue.extend({
  name: 'ChatRoomNameEditor',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    roomId: {
      type: String,
      required: true,
    },
    currentName: {
      type: String,
      required: false,
      default: '',
    },
  },

  data: () => ({
    roomName: '',
    loading: false,
  }),

  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
  },

  watch: {
    currentName(to, from) {
      if (to !== from) {
        this.roomName = to;
      }
    },
    dialog(to, from) {
      // 開いたときに名称を最新化
      if (to && !from) {
        this.roomName = this.currentName;
      }
    },
  },

  methods: {
    async handleClickEdit() {
      if (!this.roomName) {
        this.openErrorSnackBar({
          message: 'ルーム名は必須です。',
        });
        return;
      }
      this.loading = true;
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.editChatRoomName()]),
      );
      this.loading = false;
    },
    /**
     * ルームを作成する
     */
    async editChatRoomName() {
      try {
        await this.$apollo.mutate({
          mutation: /* GraphQL */ gql`
            mutation updateChatRoom($roomId: String, $roomName: String) {
              updateChatRoom(
                where: { Id: $roomId }
                data: { Name: $roomName }
              ) {
                Id
                Name
                CDS_T_Disaster__c
              }
            }
          `,
          variables: {
            roomId: this.roomId,
            roomName: this.roomName,
          },
        });
        await this.$nextTick();
        this.dialog = false;
        this.saveComplete('ルーム名を変更しました。');
        this.$emit('room-name-changed', this.roomName);
        // 名前を戻す
        this.roomName = this.currentName;
      } catch (error) {
        // throw new Error('ルーム名の変更に失敗しました。' + error);
        this.openErrorSnackBar({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          message: `ルーム名の変更に失敗しました。 ${(error as any).message}`,
        });
      }
    },

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
});
