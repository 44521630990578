import { render, staticRenderFns } from "./ChatAvatar.vue?vue&type=template&id=5c4d805c&scoped=true&"
import script from "./ChatAvatar.vue?vue&type=script&lang=js&"
export * from "./ChatAvatar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4d805c",
  null
  
)

export default component.exports