import { render, staticRenderFns } from "./DisasterDetail.vue?vue&type=template&id=6bb80944&scoped=true&"
import script from "./DisasterDetail.vue?vue&type=script&lang=js&"
export * from "./DisasterDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bb80944",
  null
  
)

export default component.exports